<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Editar Cuenta por Pagar</h1>

    <h3 class="form-division">Información general</h3>
    <div class="invoice-add-item">
      <b-field
        class="invoice-add-secondary-item-four"
        label-position="inside"
        label="Proveedor o Acreedor (Req)"
      >
        <b-select
          expanded
          placeholder="Proveedor o Acreedor"
          icon="earth"
          v-model="currentExpenseDetails.CLAVE_PRV._id"
          disabled
        >
          <option value="" disabled>Seleccione un proveedor o acreedor</option>
          <option
            v-for="provider in providers"
            :value="provider._id"
            :key="provider.NOMBRE_PRV"
          >
            {{ provider.NOMBRE_PRV }}
          </option>
        </b-select>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-four"
        label-position="inside"
        label="Folio de gasto (Req)"
      >
        <b-input
          placeholder="Folio"
          icon="pound-box-outline"
          v-model="currentExpenseDetails.NUM_FOLIO"
          type="number"
          step="any"
          disabled
        >
        </b-input>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-four"
        label-position="inside"
        label="Fecha de gasto (Req)"
      >
        <b-datepicker
          :show-week-number="true"
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          placeholder="Fecha de gasto"
          icon="calendar-today"
          v-model="currentExpenseDetails.FECHA_FOR"
          trap-focus
        >
        </b-datepicker>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-four"
        label-position="inside"
        label="Almacen (Opc)"
      >
        <b-select
          placeholder="Almacen"
          icon="warehouse"
          v-model="currentExpenseDetails.CLAVE_ALMACEN._id"
          disabled
          expanded
        >
          <option value="" disabled>Seleccione un almacén</option>
          <option
            v-for="warehouse in warehouses"
            :value="warehouse._id"
            :key="warehouse.CLAVE_ALMACEN"
          >
            {{ warehouse.NOMBRE_ALM }}
          </option>
        </b-select>
      </b-field>
    </div>

    <div class="invoice-div-bottom-div">
      <div class="invoice-div-bottom-div">
        <h3 class="form-division">Detalles del gasto</h3>

        <div class="invoice-add-item add-global-modal-name">
          <b-field
            class="invoice-add-secondary-item-one special-big-margin"
            label-position="inside"
            label="Número de factura (Req)"
          >
            <b-input
              placeholder="Número de factura"
              icon="numeric-9-circle-outline"
              type="text"
              v-model="currentExpenseDetails.FACTURA"
            >
            </b-input>
          </b-field>
          <b-field
            class="invoice-add-secondary-item-three special-big-margin"
            label-position="inside"
            label="Número de serie (Opc)"
          >
            <b-input
              placeholder="Número de serie"
              icon="numeric-8-circle-outline"
              type="text"
              v-model="currentExpenseDetails.SERIE"
            >
            </b-input>
          </b-field>
          <b-field
            class="invoice-add-secondary-item-three special-big-margin"
            label-position="inside"
            label="Fecha de factura (Req)"
          >
            <b-datepicker
              :show-week-number="true"
              :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
              :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
              :month-names="[
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre',
              ]"
              placeholder="Fecha de creación"
              icon="calendar-today"
              trap-focus
              v-model="currentExpenseDetails.FECHA_FAC_FOR"
            >
            </b-datepicker>
          </b-field>
          <b-field
            class="invoice-add-secondary-item-three special-big-margin"
            label-position="inside"
            label="Comentarios (Opc)"
          >
            <b-input
              placeholder="Comentarios"
              type="text"
              icon="magnify-plus-outline"
              v-model="currentExpenseDetails.OBSERVA"
            >
            </b-input>
          </b-field>
          <b-field
            class="invoice-add-secondary-item-three special-big-margin"
            label-position="inside"
            label="Plazo en días (Req)"
          >
            <b-input
              placeholder="Plazo"
              icon="numeric-4-circle-outline"
              type="text"
              v-model="currentExpenseDetails.PERI"
            >
            </b-input>
          </b-field>
          <b-field
            class="invoice-add-secondary-item-three special-big-margin"
            label-position="inside"
            label="Fecha de vencimiento (Req)"
          >
            <b-datepicker
              :show-week-number="true"
              :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
              :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
              :month-names="[
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre',
              ]"
              placeholder="Fecha de vencimiento"
              icon="calendar-today"
              trap-focus
              v-model="currentExpenseDetails.FECHA_VEN_FOR"
            >
            </b-datepicker>
          </b-field>
          <b-field
            class="invoice-add-secondary-item-three special-big-margin"
            label-position="inside"
            label="Días vencida (Req)"
          >
            <b-input
              placeholder="Días vencida"
              icon="numeric-4-circle-outline"
              type="text"
              v-model="currentExpenseDetails.DIAS_VEN"
              disabled
            >
            </b-input>
          </b-field>
        </div>
      </div>

      <div class="table-header providers-list-global table-header-border">
        Cantidad de elementos en gasto:
        <b>{{ currentExpenseDetails.GASTO_DET.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="currentExpenseDetails.GASTO_DET"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="expenseDetails"
        custom-row-key="RENGLON"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        class="purchase-order-table"
        :selected.sync="selected"
        default-sort="CONCEP_IE"
        scrollable
      >
        <template #empty>
          <div class="has-text-centered">No hay detalles del gasto</div>
        </template>

        <template slot-scope="props">
          <b-table-column label="Concepto" field="CONCEP_IE" sortable>
            <b-input
              placeholder="Ejemplo: Pago de Luz"
              expanded
              v-model="props.row.CONCEP_IE"
              required
              disabled
            ></b-input>
          </b-table-column>

          <b-table-column label="Descripción" field="DESCRIBEAR" sortable>
            <b-input
              placeholder="Ejemplo: Pago de enero"
              expanded
              v-model="props.row.DESCRIBEAR"
              required
              disabled
            ></b-input>
          </b-table-column>

          <b-table-column label="Cantidad" field="CANTIDAD" sortable>
            <b-input
              placeholder="Ejemplo: Pago de enero"
              expanded
              v-model="props.row.CANTIDAD"
              required
              type="number"
              step="any"
            ></b-input>
          </b-table-column>

          <b-table-column label="Costo" field="PRECIO_U" sortable>
            <MoneyInput
              currency="MXN"
              :digits="5"
              placeholder="Ejemplo: 16"
              v-model="props.row.PRECIO_U"
              :required="true"
              :disabled="false"
            >
            </MoneyInput>
          </b-table-column>

          <b-table-column label="Total de IVA" field="IVA_GTO" sortable>
            <MoneyInput
              currency="MXN"
              :digits="5"
              placeholder="Ejemplo: 16"
              v-model="props.row.IVA_GTO"
              :required="true"
              :disabled="false"
            >
            </MoneyInput>
          </b-table-column>

          <b-table-column label="Total de IEPS" field="IEPS_GTO" sortable>
            <MoneyInput
              currency="MXN"
              :digits="5"
              placeholder="Ejemplo: 16"
              v-model="props.row.IEPS_GTO"
              :required="true"
              :disabled="false"
            >
            </MoneyInput>
          </b-table-column>

          <b-table-column label="Total" field="TOTAL" sortable>
            <MoneyInput
              currency="MXN"
              :digits="5"
              placeholder="Ejemplo: 16"
              v-model="props.row.TOTAL"
              :required="true"
              :disabled="false"
            >
            </MoneyInput>
          </b-table-column>
        </template>
      </b-table>
      <div class="special-margin">
        <div class="invoice-payment-total-info-costs">
          <div class="invoice-payment-total-left"></div>
          <div class="invoice-payment-total-rigth">
            <strong>Subtotal:</strong>
            <MoneyInput
              currency="MXN"
              :digits="5"
              placeholder="Ejemplo: 16"
              v-model="currentExpenseDetails.IMPORTE_E"
              :required="false"
              :disabled="false"
            >
            </MoneyInput>
          </div>
        </div>

        <div class="invoice-payment-total-info-discounts">
          <div class="invoice-payment-total-left"></div>
          <div class="invoice-payment-total-rigth">
            <strong>Indirectos:</strong>
            <MoneyInput
              currency="MXN"
              :digits="5"
              placeholder="Ejemplo: 16"
              v-model="currentExpenseDetails.COST_GTO"
              :required="false"
              :disabled="false"
            >
            </MoneyInput>
          </div>
        </div>

        <div class="invoice-payment-total-info-discounts">
          <div class="invoice-payment-total-left"></div>
          <div class="invoice-payment-total-rigth">
            <strong>Descuentos:</strong>
            <MoneyInput
              currency="MXN"
              :digits="5"
              placeholder="Ejemplo: 16"
              v-model="currentExpenseDetails.DESC_GTO"
              :required="false"
              :disabled="false"
            >
            </MoneyInput>
          </div>
        </div>

        <div class="invoice-payment-total-info-discounts">
          <div class="invoice-payment-total-left"></div>
          <div class="invoice-payment-total-rigth">
            <strong>IVA:</strong>
            <MoneyInput
              currency="MXN"
              :digits="5"
              placeholder="Ejemplo: 16"
              v-model="currentExpenseDetails.IVA_GTO"
              :required="false"
              :disabled="false"
            >
            </MoneyInput>
          </div>
        </div>

        <div class="invoice-payment-total-info-discounts">
          <div class="invoice-payment-total-left"></div>
          <div class="invoice-payment-total-rigth">
            <strong>IEPS:</strong>
            <MoneyInput
              currency="MXN"
              :digits="5"
              placeholder="Ejemplo: 16"
              v-model="currentExpenseDetails.IEPS_GTO"
              :required="false"
              :disabled="false"
            >
            </MoneyInput>
          </div>
        </div>

        <div class="invoice-payment-total-info">
          <div class="invoice-payment-total-left"></div>
          <div class="invoice-payment-total-rigth">
            <strong>Total:</strong>
            <MoneyInput
              currency="MXN"
              :digits="5"
              placeholder="Ejemplo: 16"
              v-model="currentExpenseDetails.TOTAL_GTO"
              :required="false"
              :disabled="false"
            >
            </MoneyInput>
          </div>
        </div>
      </div>
      <div class="add-global-controls">
        <b-button type="is-success" @click="editAccount()"
          >Editar cuenta por pagar</b-button
        >
        <b-button type="is-danger" @click="closeModal()" class="cancel-button"
          >Regresar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { basicRuleOfThree } from "../../../utils/fns";

export default {
  name: "EditExpense",
  props: ["expense"],
  data() {
    return {
      currentExpenseDetails: { ...this.expense },
      moment: moment,
      perPage: 25,
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
    };
  },
  created() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async editAccount() {
      try {
        if (!this.validateTotal()) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al editar la cuenta por pagar, revise que el total no sea mayor o menor al porcentaje permitido por la compañía",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });

          return;
        }
        let response = await this.$store.dispatch("EDITEXPENSE", {
          expenseInformation: {
            ...this.currentExpenseDetails,
            FECHA_FAC: this.currentExpenseDetails.FECHA_FAC_FOR,
            FECHA_VEN: this.currentExpenseDetails.FECHA_VEN_FOR,
            FECHA: this.currentExpenseDetails.FECHA_FOR,
          },
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se editó exitosamente la cuenta por pagar",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al editar la cuenta por pagar en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al editar la cuenta por pagar en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    validateTotal() {
      const differenceTotals =
        Number(this.expense.TOTAL_GTO) -
        Number(this.currentExpenseDetails.TOTAL_GTO);

      if (differenceTotals < 0) {
        if (
          this.companyInformation.POR_SUP_REVISION_COMPRAS &&
          Number(this.companyInformation.POR_SUP_REVISION_COMPRAS) > 0
        ) {
          const totalPercentage = basicRuleOfThree(
            100,
            Number(this.expense.TOTAL_GTO),
            Number(this.companyInformation.POR_SUP_REVISION_COMPRAS)
          );

          return Math.abs(differenceTotals) < totalPercentage;
        }
      } else if (differenceTotals > 0) {
        if (
          this.companyInformation.POR_INF_REVISION_COMPRAS &&
          Number(this.companyInformation.POR_INF_REVISION_COMPRAS) > 0
        ) {
          const totalPercentage = basicRuleOfThree(
            100,
            Number(this.expense.TOTAL_GTO),
            Number(this.companyInformation.POR_INF_REVISION_COMPRAS)
          );

          return Math.abs(differenceTotals) < totalPercentage;
        }
      }

      return true;
    },
  },
  mounted() {
    if (
      this.currentExpenseDetails.RESTA !== undefined &&
      this.currentExpenseDetails.RESTA !== null
    ) {
      this.currentExpenseDetails.PAGADO =
        this.currentExpenseDetails.TOTAL_GTO - this.currentExpenseDetails.RESTA;
    } else {
      this.currentExpenseDetails.PAGADO = 0;
      this.currentExpenseDetails.RESTA = this.currentExpenseDetails.TOTAL_GTO;
    }
    this.currentExpenseDetails.PAGADO_POR = Number(
      basicRuleOfThree(
        this.currentExpenseDetails.TOTAL_GTO,
        100,
        this.currentExpenseDetails.PAGADO
      ).toFixed(2)
    );
    this.currentExpenseDetails.PAGADO =
      this.currentExpenseDetails.TOTAL_GTO - this.currentExpenseDetails.RESTA;
    this.currentExpenseDetails.FECHA_FAC_FOR = new Date(
      this.currentExpenseDetails.FECHA_FAC
    );
    this.currentExpenseDetails.FECHA_MOD_FOR = new Date(
      this.currentExpenseDetails.FECHA_MOD
    );
    this.currentExpenseDetails.FECHA_FOR = new Date(
      this.currentExpenseDetails.FECHA
    );
    this.currentExpenseDetails.FECHA_VEN_FOR = new Date(
      this.currentExpenseDetails.FECHA_VEN
    );
    this.currentExpenseDetails.DIAS_VEN = this.currentExpenseDetails.DIAS_VEN
      ? this.currentExpenseDetails.DIAS_VEN
      : 0;
    this.currentExpenseDetails.GASTO_DET =
      this.currentExpenseDetails.GASTO_DET.map((singleExpenseDetail) => {
        const singleExpenseDetailsWithProps = { ...singleExpenseDetail };
        if (
          singleExpenseDetailsWithProps.RESTA !== undefined &&
          singleExpenseDetailsWithProps.RESTA !== null
        ) {
          singleExpenseDetailsWithProps.PAGADO =
            singleExpenseDetailsWithProps.TOTAL -
            singleExpenseDetailsWithProps.RESTA;
        } else {
          singleExpenseDetailsWithProps.PAGADO = 0;
          singleExpenseDetailsWithProps.RESTA =
            singleExpenseDetailsWithProps.TOTAL;
        }
        singleExpenseDetailsWithProps.PAGADO_POR =
          singleExpenseDetailsWithProps.TOTAL !== 0
            ? `${basicRuleOfThree(
                singleExpenseDetailsWithProps.TOTAL,
                100,
                singleExpenseDetailsWithProps.PAGADO
              ).toFixed(2)}%`
            : "100%";
        return singleExpenseDetailsWithProps;
      });
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
    providers() {
      return this.$store.getters.PROVIDERS;
    },
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
  },
  watch: {
    /**
     * @desc Select default opened details, to open a specific warehouse
     */
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
};
</script>

<style scoped>
@import "../../Purchases/style/AddPurchaseOrder.css";
@import "../../Global/style/Global.css";
</style>
